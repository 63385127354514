import axios from "@/axios.js";

export const actions = {
  postRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      // const token = getters.token;
      axios({
        url: payload.path,
        data: payload.data,
        method: "POST",
        headers: {
          // Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRequest({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      // const token = getters.token;

      axios({
        url: `${payload.path}`,
        method: "GET",
        headers: {
          // Authorization: `bearer ${token}`,
        },
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
