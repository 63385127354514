<template>
  <!-- position-sticky top-0 -->
  <div>
    <marquee-text :duration="30" class="mar" :repeat="10">
      At checkout, you could take advantage of our Lease to Own financing option
      to buy your choice of products and solution. Terms and conditions apply.
      <!-- There is a scheduled maintenance for tomorrow 26th of January on this
      platform, from 8pm to 11pm. Kindly bear with us. -->
    </marquee-text>
    <nav
      id="navbar-main"
      class="navbar navbar-main navbar-expand-lg navbar-light py-2"
    >
      <div class="container relative">
        <router-link class="navbar-brand mr-lg-5" to="/">
          <!-- /assets/images/imperium_logo.png -->
          <img src="/assets/images/imperium_logo.png" />
        </router-link>
        <button
          v-if="!toogle"
          class="navbar-toggler"
          @click="toogle = true"
          style="z-index: 9999"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          :class="{
            'navbar-collapse': true,
            collapse: true,
            show: toogle == true,
          }"
          id="navbar_global"
          style="outline: 0"
        >
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <router-link to="/">
                  <img src="/assets/images/imperium_logo.png" />
                </router-link>
              </div>
              <div class="col-6 collapse-close">
                <button
                  v-if="toogle"
                  @click="toogle = false"
                  class="navbar-toggler"
                >
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <ul
            class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto"
          >
            <li class="nav-item">
              <router-link to="/about" class="nav-link"> About </router-link>
            </li>
            <li class="nav-item">
              <!-- <a
                href="https://shop.imperiumng.com/energy-calculator"
                rel="noopener noreferrer"
                class="nav-link"
              >
                Calculator
              </a> -->
              <router-link to="/energy-calculator" class="nav-link">
                Calculator
              </router-link>
              <!-- <router-link to="/energy-calculator" class="nav-link">
                Calculator
              </router-link> -->
            </li>
            <li class="nav-item">
              <!-- <a href="https://shop.imperiumng.com/" class="nav-link"> Shop </a> -->
              <router-link to="/newsales" class="nav-link"> Shop </router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link to="/special-offers" class="nav-link">
                Special Offers
              </router-link>
            </li> -->
            <li class="nav-item">
              <a
                target="_blank"
                href="https://sterling.ng/savingsaccounts/"
                class="nav-link"
              >
                Open Sterling Account
              </a>
            </li>

            <li class="nav-item">
              <router-link to="/energyreport" class="nav-link">
                Downloads
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/testimonials" class="nav-link">
                Testimonials
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <img src="@/assets/images/hangdownstar.svg" alt="" class="hds" /> -->
      </div>
    </nav>
    <GotoTop />
  </div>
</template>

<script>
import GotoTop from "@/components/App/GotoTop.vue";
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "AppHeader",
  data() {
    return {
      dur: 30,
      toogle: false,
      displayCategories: false,
      itemAdded: false,
    };
  },

  computed: {},
  components: { GotoTop, MarqueeText },
};
</script>
<style scoped>
.hds {
  position: absolute;
  width: 500px;
  right: 0;
  top: 0;
}
@media (max-width: 768px) {
  .hds {
    width: 200px;
  }
}

.relative {
  /* position: relative; */
}
.mar {
  background-color: #255e13;
  padding: 6px 0;
}
.marquee-text-text {
  margin-right: 250px;
  font-size: 2rem;
  color: #fdd826;
  font-weight: 600;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
</style>
