import Vue from "vue";
import VueRouter from "vue-router";

import AppHeader from "../layouts/AppHeader";
import AppFooter from "../layouts/AppFooter";
import NotFound from "../views/404"

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Maintenance",
  //   components: {
  //     // header: AppHeader,
  //     default: () => import("../views/Maintenance.vue"),
  //     // footer: AppFooter,
  //   },
  // },
  {
    path: "/",
    name: "Home",
    components: {
      header: AppHeader,
      default: () => import("../views/Home.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/about",
    name: "About",
    components: {
      header: AppHeader,
      default: () => import("../views/About.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/faqs",
    name: "Faqs",
    components: {
      header: AppHeader,
      default: () => import("../views/Faqs.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/energy-calculator",
    name: "Calculator",
    components: {
      header: AppHeader,
      default: () => import("../views/Calculator.vue"),
      footer: AppFooter,
    },
    // beforeEnter(to, from, next) {
    //   window.location.href = "https://shop.imperiumng.com/calculator";
    // }
  },
  {
    path: "/calculator-result",
    name: "calculator-result",
    components: {
      header: AppHeader,
      default: () => import("../views/CalculatorResult.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/blogs",
    name: "Blogs",
    components: {
      header: AppHeader,
      default: () => import("../views/Blogs.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/blog/:slug",
    name: "BlogView",
    components: {
      header: AppHeader,
      default: () => import("../views/BlogView.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/terms",
    name: "Terms",
    components: {
      header: AppHeader,
      default: () => import("../views/Terms.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    components: {
      header: AppHeader,
      default: () => import("../views/Privacy.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/how-to",
    name: "HowTo",
    components: {
      header: AppHeader,
      default: () => import("../views/HowTo.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    components: {
      header: AppHeader,
      default: () => import("../views/Contact.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/become-vendor",
    name: "BecomeVendor",
    components: {
      header: AppHeader,
      default: () => import("../views/BecomeVendor.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/power-as-a-service",
    name: "power-as-a-service",
    components: {
      header: AppHeader,
      default: () => import("../views/PowerService.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/energyreport",
    name: "Energy-report",
    components: {
      header: AppHeader,
      default: () => import("../views/Stears.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/user/confirm",
    name: "confirm-user",
    components: {
      header: AppHeader,
      default: () => import("../views/VerifyUser.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/testimonials",
    name: "testimonials",
    components: {
      header: AppHeader,
      default: () => import("../views/Testimonials.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/special-offers",
    name: "special-offers",
    components: {
      header: AppHeader,
      default: () => import("../views/NewSalesV2.vue"),
      // default: () => import("../views/SpecialOffers.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/onewoman",
    name: "one-woman",
    components: {
      header: AppHeader,
      default: () => import("../views/NewSalesV2OneWoman.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/sterling-human",
    name: "sterling-human",
    components: {
      header: AppHeader,
      default: () => import("../views/HcNewSales.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/newsales",
    name: "new-sales",
    components: {
      header: AppHeader,
      // default: () => import("../views/NewSales.vue"),
      default: () => import("../views/NewSalesV2.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/newsales-tbh",
    name: "new-sales-tbh",
    components: {
      header: AppHeader,
      // default: () => import("../views/NewSales.vue"),
      default: () => import("../views/NewSalesV2Tbh.vue"),
      footer: AppFooter,
    },
  },
  {
    path: "/apply",
    name: "new-salesv2",
    components: {
      header: AppHeader,
      default: () => import("../views/NewSalesV2.vue"),
      footer: AppFooter,
    },
  },

  //Catchall 404
  {
    path: '/:catchAll(.*)',
    name: '404',
    components: {
      header: AppHeader,
      default: NotFound,
      footer: AppFooter,
    },
  }
  // {
  //   path: '/:catchAll(.*)',
  //   name: '404',
  //   components: {
  //     // header: AppHeader,
  //     default: () => import("../views/Maintenance.vue"),
  //     // footer: AppFooter,
  //   },
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
