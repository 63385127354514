import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.min.css";
import helpers from "./helpers";

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(plugin);

Vue.config.productionTip = false;

Vue.use(VueIziToast);
Vue.prototype.$toastPosition = {
  position: "topCenter",
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
