<template>
  <div id="app">
    <router-view name="header"></router-view>
    <router-view />
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
export default {
  mounted() {
    // var text = "tesdsxc fgs wbwsddbdsb";
    // console.log(text);
    // var etext = this.$helpers.encrypt(text)
    // console.log(etext);
    // var dtext = this.$helpers.decrypt(
    //   "7095e1fbb0101ee63a4a536bf29495becb06a222a429be64274e7061d39aa5c44dae44741ff4b2a0d68deeaff7a92b45d90e18d34e563ee70411364e28effc9f16ef34f7eccac1220e110a0cb4ebc6a0fc838cf62b7e9f146809376757918c273343498b90c5216afe0914a968b785df"
    // );
    // console.log(JSON.parse(dtext));
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
.iziToast-wrapper {
  z-index: 99999999999999 !important;
}
.w-full {
  width: 100%;
}
</style>
